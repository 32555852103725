@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700&display=swap');
/*
.swiper {W
  width: 100%;
  height: 100%;
}

.swiper-slide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}*/

/* Hide scrollbar from chrome */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-family: 'Plus Jakarta Sans', sans-serif;
  /* Firefox */
}

@layer components {
  .jertt-swipes {
    @apply text-jerttblue-500 hover:text-inherit ease-linear duration-500 cursor-pointer;
  }
  .btn-jj {
    @apply ease-linear duration-300 text-white w-[200px] rounded-md font-medium my-6 px-5 py-3 cursor-pointer;
  }
}

.accordion-content {
  transition: max-height 0.3s ease-out, padding 0.3s ease;
}

.mapa {
  filter: grayscale(1) contrast(1.2) opacity(0.16);
}